<template>
  <div class="page-cu-container">
    <div class="page-cu-top">
      <a-form layout="inline">
        <a-form-item>
          <a-input v-model="page.key" placeholder="请输入关键字"></a-input>
        </a-form-item>
        <a-form-item>
          <a-select
            placeholder="请选择类型"
            v-model="page.type"
            style="width: 200px"
          >
            <a-select-option value="1"> 合同 </a-select-option>
            <a-select-option value="2"> 协议 </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: 200px"
            v-model="page.state"
            placeholder="请选择状态"
          >
            <a-select-option value="1">有效</a-select-option>
            <a-select-option value="2">无效</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="searchList" icon="search"
            >搜索</a-button
          >
        </a-form-item>
        <a-form-item>
          <a-button type="default" @click="addAction" icon="plus-circle"
            >新增</a-button
          >
        </a-form-item>
        <a-form-item>
          <a-button
            :icon="getExicon.icon"
            :disabled='getExicon.ed'
            @click="$exportFun('/export/contract', page)"
            >导出</a-button
          >
        </a-form-item>
      </a-form>
    </div>
    <div class="page-cu-main">
        <a-table
          :columns="columns"
          :data-source="list"
          rowKey="id"
          bordered
          :pagination="false"
        >
          <template slot="state" slot-scope="text, record">
            <a-tag color="green" v-if="text == '1'">启用</a-tag>
            <a-tag color="orange" v-if="text == '2'">禁用</a-tag>
          </template>
          <template slot="is_special" slot-scope="text, record">
            <!-- <a-tag color="green" v-if="text == '1'">是</a-tag>
             <a-tag color="orange" v-if="text == '2'">否</a-tag> -->
            <span v-if="text == '1'">是</span>
            <span v-if="text == '2'">否</span>
          </template>
          <!-- <span slot="action" slot-scope="text, record">
            <a @click="editAction(record)"><a-icon type="edit" /> 编辑</a>
            <a-divider type="vertical" />
            <a @click="delConfirm(record.id)" class="text-red"
              ><a-icon type="close" />删除</a
            >
          </span> -->
          <template slot="action" slot-scope="text, record">
            <a-button
              @click="() => editAction(record['id'])"
              type="link"
              size="small"
              icon="edit"
              >编辑</a-button
            >
            <a-button
              @click="() => delConfirm(record['id'])"
              type="link"
              size="small"
              icon="delete"
              >删除</a-button
            >
            <a-button
              type="link"
              size="small"
              icon="minus-circle"
              v-if="record['state'] == '1'"
              @click="() => enabledState(record['id'], 2, '你确定要禁用吗？')"
              >禁用</a-button
            >
            <a-button
              type="link"
              size="small"
              icon="check-circle"
              v-if="record['state'] == '2'"
              @click="enabledState(record['id'], 1, '你确定要启用吗？')"
              >启用</a-button
            >
          </template>
        </a-table>
        <div class="page-cu-pagination">
          <a-pagination
          :show-total="(total, range) => `总数：${total} 条`"
            :page-size.sync="page.pageSize"
            :total="page.totalRow"
            v-model="page.start"
            @change="changePage"
          />
        </div>
    </div>

    <a-modal
      title="新增"
      :visible="actionVisible"
      @ok="actionData"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancel"
      :confirmLoading="$store.state.requestLoading"
    >
      <a-form-model
        ref="formModel"
        :rules="formModelRules"
        :model="formModel"
        layout="vertical"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="类型" prop="type">
          <a-radio-group v-model="formModel.type">
            <a-radio value="1"> 合同 </a-radio>
            <a-radio value="2"> 协议 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="标题" prop="title">
          <a-input v-model="formModel.title" placeholder="请输入标题"></a-input>
        </a-form-model-item>
        <a-form-model-item label="编号" prop="code">
          <a-input v-model="formModel.code" placeholder="请输入编号"></a-input>
        </a-form-model-item>
        <a-form-model-item label="附件" prop="contract_type_url">
          <custom-file-list
            :img_url.sync="formModel.contract_type_url"
            :type="'single'"
            @changeCustomFile="changeImgUrl"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import * as Api from "./api";
export default {
  name: "index",
  data() {
    return {
      isAdd: true, // TODO: 是否是新增操作
      formModel: {
        id: "",
        type: "",
        title: "",
        code: "",
        contract_type_url: "",
      },

      formModelRules: {
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        code: [{ required: true, message: "请输入编号", trigger: "change" }],
        title: [{ required: true, message: "请输入标题", trigger: "change" }],
      },

      columns: [
        {
          title: "合同编号",
          dataIndex: "code",
        },
        {
          title: "合同标题",
          dataIndex: "title",
        },
        // {
        //   title: "是否特殊合同",
        //   dataIndex: "is_special",
        //   scopedSlots: { customRender: "is_special" },
        // },
        {
          title: "合同上传时间",
          dataIndex: "create_time",
        },
        {
          title: "状态",
          dataIndex: "state",
          scopedSlots: { customRender: "state" },
        },
        {
          title: "操作",
          width: 300,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      page: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
        key: "",
        type: undefined,
        state: undefined,
      },
      list: [],
      actionVisible: false,
      record: "",
      menuList: [],
      showTree: false,
      expandedKeys: [],
      autoExpandParent: true,
      treeFields: {
        children: "children",
        title: "name",
        key: "id",
      },
      selectedIds: [],
      selectHalf: [],
      // 解决数据树父子节点选中的问题
      checkedKeys: { checked: [], halfChecked: [] },
    };
  },
  computed: {
    getExicon() {
      return this.$store.state.EXICON;
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    /**
     * 添加，更新
     *
     */
    actionData() {
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          let res = null;
          console.log(this.formModel);
          if (this.formModel["id"]) {
            res = await Api.Update(this.formModel);
          } else {
            res = await Api.Save(this.formModel);
          }

          if (res && res["code"] == "0") {
            this.$message.success(res.message);
            this.actionVisible = false;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },

    /*
     * 表单重置
     **/
    resetFormModel() {
      for (let key in this.formModel) {
        this.formModel[key] = "";
      }
    },
    // 菜单选择
    handlerCheck(n, e) {
      this.checkedKeys = { checked: n, halfChecked: e.halfCheckedKeys };
    },
    // 打开添加合同窗
    addAction() {
      this.isAdd = true;
      this.resetFormModel();
      this.actionVisible = true;
    },

    // 编辑
    async editAction(id) {
      this.isAdd = false;
      this.resetFormModel();
      let params = {
        id: id,
      };
      let res = await Api.DetailMenu(params);
      Object.keys(this.formModel).forEach((key) => {
        this.formModel[key] = res.target[key];
      });
      this.actionVisible = true;
    },

    // 关闭窗口
    cancel() {
      this.actionVisible = false;
    },

    // 删除确认
    delConfirm(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要删除吗?",
        onOk() {
          that.del(id);
        },
        onCancel() {},
      });
    },

    // 删除
    async del(id) {
      let res = await Api.Delete({ id: id });
      if (res && res.code == "0") {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },
    // 列表
    async getList() {
      let res = await Api.List({ ...this.page });
      this.page.totalRow = res.page.totalRow;
      this.page.pageSize = res.page.pageSize;
      this.list = res.page.list;
    },

    changePage(currentPage) {
      this.page.start = currentPage;
      this.getList();
    },
    // 搜索
    searchList() {
      this.page.start = 1;
      this.getList();
    },
    // 启用 / 禁用
    enabledState(id, state, msg) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "success",
        okText: "确定",
        cancelText: "取消",
        content: msg,
        async onOk() {
          let params = {
            id: id,
            state: state,
          };
          let res = await Api.AdminEnabled(params);
          if (res && res["code"] == "0") {
            that.getList();
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },

    /**
     * 合同上传完成的事件
     * @author wheat
     * */
    changeImgUrl(url) {
      this.formModel.contract_type_url = url;
    },
  },
};
</script>

<style scoped>
</style>

